import React from "react";
import {getResponseErrorMessage} from "../../support/utility";
import Alert from "../../components/ui/Alert/Alert";

const ErrorPage = (props) => {

    const message = getResponseErrorMessage(props.error);

    return (
        <div className={"min-h-screen min-w-screen flex items-center justify-center"}>
            <div className={"flex flex-col items-center"}>
                <Alert type={"danger"}>
                    {message}
                </Alert>
            </div>
        </div>
    );
}

export default ErrorPage;