import React from 'react';
import PropTypes from 'prop-types';

import classes from './DescriptionItem.module.css';

const Item = (props) => {
    return (
        <div className={`flex items-center w-full ${props.className} gap-2.5`}>
            <div className={`rounded-full bg-red-500 w-5 h-5 flex items-center justify-center ${classes.ItemIcon}`}>
                <i className={"ti-check-small text-xs relative"}/>
            </div>
            <h3 className={"text-sm"}>{props.title}</h3>
        </div>
    )
}

Item.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
}

export default Item;