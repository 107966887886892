import React from 'react';
import PropTypes from "prop-types";

import {getLanguageCode} from "../../../support/utility";
import translate from "../../../services/translate";

const LanguageSwitcher = (props) => {
    const [clicked, setClicked] = React.useState(false);
    const onLanguagePreviewClicked = () => {
        setClicked((clicked) => {
            return !clicked;
        })
    }

    const switchLanguage = (languageCode) => {
        window.location.href = translate.switchUrl(languageCode);
    }

    const liElements = Object.keys(translate.languages())
        .filter((key) => key !== translate.languageCode())
        .map((key) => {
            return (
                <li key={`LanguageSwitcher-item-{${key}`}
                    onClick={() => switchLanguage(key)}
                    className={"transition-all duration-300 rounded-lg p-2 cursor-pointer hover:bg-slate-700 uppercase"}>{key}</li>
            )
        });

    let conflictingClassName = '';
    if (!props.className || !props.className.includes("absolute")) {
        conflictingClassName = "relative";
    }

    return (
        <div className={`inline-flex flex-col gap-3 ${conflictingClassName} items-center ${props.className}`}>
            <div className={"p-2 bg-slate-500 rounded-lg text-white cursor-pointer flex gap-1 ltr"}
                 onClick={onLanguagePreviewClicked}>
                <i className={"ti-arrow-down relative -top-0.5"}/>
                <span className={"uppercase"}>{getLanguageCode()}</span>
            </div>
            <ul className={`bg-slate-500 rounded-lg text-white ${clicked ? '' : 'hidden'} absolute top-full mt-2`}>
                {liElements}
            </ul>
        </div>
    )
}

LanguageSwitcher.defaultProps = {
    className: ""
}

LanguageSwitcher.propTypes = {
    className: PropTypes.string
}

export default LanguageSwitcher;