import * as actionTypes from '../actions/actionTypes';

const initialState = {
    modals: []
};

const showModal = (state, action) => {
    const options = action.options ? action.options : state.options;
    const newModals = [...state.modals];
    newModals.push({
        isOpen: true,
        options: options
    });

    return {
        modals: newModals
    }
};

const closeModal = (state) => {
    const newModals = [...state.modals];
    newModals.pop();

    return {
        modals: newModals
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_MODAL:
            return showModal(state, action);
        case actionTypes.CLOSE_MODAL:
            return closeModal(state);
        default:
            return state;
    }
};

export default reducer;