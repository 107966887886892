export const FETCH_APPLICATION_INFO_SUCCESS = 'FETCH_APPLICATION_INFO_SUCCESS';

export const PUSH_TOAST_MESSAGE = "PUSH_TOAST_MESSAGE";
export const POP_TOAST_MESSAGE = "POP_TOAST_MESSAGE";
export const REMOVE_TOAST_MESSAGE = "REMOVE_TOAST_MESSAGE";

export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const FETCH_SUBSCRIPTION_TYPES = "FETCH_SUBSCRIPTION_TYPES";
export const FETCH_SUBSCRIPTION_TYPES_START = "FETCH_SUBSCRIPTION_TYPES_START";
export const FETCH_SUBSCRIPTION_TYPES_SUCCESS = "FETCH_SUBSCRIPTION_TYPES_SUCCESS";
export const FETCH_SUBSCRIPTION_TYPES_FAIL = "FETCH_SUBSCRIPTION_TYPES_FAIL";
export const FETCH_SUBSCRIPTION_TYPES_CLEAR_ERROR = "FETCH_SUBSCRIPTION_TYPES_CLEAR_ERROR";