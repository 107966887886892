import React from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import url from "../../services/url";

import Footer from "../../components/Footer";
import DescriptionCard from "./Description/DescriptionCard";
import ResponsiveGrid from "../../components/Grid/ResponsiveGrid";
import Tab from "../../components/Navigation/Tabs/Tab";
import TabList from "../../components/Navigation/Tabs/TabList";
import tiSymbolSvg from "../../assets/fonts/tawazonIcon/ti.symbol.svg";
import MetaTags from "../../components/MetaTags/MetaTags";
import LanguageSwitcher from "../../components/ui/LanguageSwitcher/LanguageSwitcher";

const ProfilePage = (props) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const tawazonLogo = (
        <a href={url.getWebsiteUrl()} className={"flex justify-center items-center ltr"}>
            <div className={"bg-white rounded-lg p-2"}>
                <svg width={"24"} height={"24"} aria-hidden="true">
                    <use xlinkHref={`${tiSymbolSvg}#ti-bird`}/>
                </svg>
            </div>

            <div className={"py-3 pl-3"}>
                <svg height={"16"} width={"90"} aria-hidden="true">
                    <use xlinkHref={`${tiSymbolSvg}#ti-app-name`}/>
                </svg>
            </div>
        </a>
    );

    const onProfileClicked = () => {
        navigate(url.get("/profile/account"))
    };

    const onBuyGiftClicked = () => {
        window.location.href = url.get("/gifts/buy");
    }

    return (
        <ResponsiveGrid>

            <MetaTags path={'pages.profile'}/>

            <LanguageSwitcher className={"absolute top-5 start-5"}/>

            <div className={"w-full"}>
                <div className={"md:hidden pb-8"}>
                    {tawazonLogo}
                </div>

                <div className={"flex justify-between mb-5 sm:mb-0"}>
                    <TabList className={"relative top-px"}>
                        <Tab active className={"active"}>{t('labels.tawazon_premium')}</Tab>
                        <Tab onClick={onProfileClicked}>{t('labels.profile')}</Tab>
                        <Tab onClick={onBuyGiftClicked}>{t('labels.buy_gift')}</Tab>
                    </TabList>

                    <div className={"hidden md:block"}>
                        {tawazonLogo}
                    </div>
                </div>

                <DescriptionCard/>
            </div>

            <Footer/>
        </ResponsiveGrid>
    )
}

export default ProfilePage;