import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import {combineReducers, createStore} from "redux";
import {QueryClient, QueryClientProvider} from "react-query";
import {HelmetProvider} from "react-helmet-async";

import toastReducer from "./store/reducers/toast";
import modalReducer from "./store/reducers/modal";
import reportWebVitals from './reportWebVitals';
import './i18n';

import './index.css';
import AppWrapper from "./AppWrapper";

const rootReducer = combineReducers({
    toast: toastReducer,
    modal: modalReducer,
});

const store = createStore(
    rootReducer
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: Infinity
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <HelmetProvider>
                        <AppWrapper/>
                    </HelmetProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
