import React from 'react';
import PropTypes from "prop-types";

import classes from './Tab.module.css';

const Tab = (props) => {
    return (
        <div onClick={props.onClick} className={`${classes.Tab} ${props.active ? classes.active : ''} py-7 px-10 cursor-pointer col-span-6`}>
            {props.children}
        </div>
    )
}

Tab.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func
}

export default Tab;