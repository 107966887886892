const labels = {
    'name': 'Name',
    'email': 'Email Address',
    'current_password': 'Current Password',
    'password': 'Password',
    'confirm_password': 'Password Confirmation',
    'log_in': 'Login',
    'sign_up': 'Signup',
    'terms': 'Terms',
    'click_here': 'Click Here',
    'submit': 'Submit',
    'new_password': 'New Password',
    'profile': 'Profile',
    'settings': 'Settings',
    'log_out': 'Logout',
    'tawazon_premium': 'Tawazon Premium',
    'profile_and_account': 'Profile & Account',
    'yes': 'Yes',
    'no': 'No',
    'cancel': 'Cancel',
    'base_account': 'Basic Account',
    'premium_account': 'Premium Account',
    'cancel_subscription': 'Cancel Subscription',
    'subscribe': 'Subscribe',
    'or': 'Or',
    'edit': 'Update',
    'close': 'Close',
    'profile_items': [
        'Integrated meditation library for body and soul',
        'New meditation every week',
        'Specialized meditation library for children!',
        'Specialized music library',
        'Unique and Specialized meditation programs',
    ],
    'subscribe_items': {
        'integrated_library': 'First time in Arabic!',
        'new_meditation_weekly': 'New meditation every week',
        'children_library': 'Specialized meditation library for children!',
        'specialized_program': 'Unique and Specialized meditation programs',
    },
    'subscription_features': 'Subscription Features',
    'current_subscription': 'Current Subscription',
    'cancellation_title': 'We are sorry to see you leave 😔',
    'redeem_your_gift': 'Redeem your Gift',
    'ender_your_code': 'Enter Code',
    'redeem': 'Redeem',
    'partner_logo': 'Partner Logo',
    'google_app_link': 'Play Store Download Link',
    'apple_app_link': 'App Store Download Link',
    'password_reset': 'Reset Password',
    'redeem_code': 'Redeem Code',
    'discount': 'Discount',
    'total': 'Total',
    'discount_code': 'Discount Code',
    'activate_code': 'Activate Code',
    'add': 'Add',
    'gift_page_title': 'Gift Tawazon',
    'received_a_gift': 'Received a gift?',
    'quantity': 'Quantity',
    'continue': 'Continue',
    'purchase': 'Purchase',
    'try_again': 'Try Again',
    'buy_gift': 'Buy Gift',
    'gift_page_slogan_1': 'Show your loved ones',
    'gift_page_slogan_2': 'how much you care!',
    'gift_page_slogan_3': 'Choose a gift, and we will handle the rest.',
    'gift_page_steps_title': 'The process is quite simple:',
    'gift_page_steps': [
        'Choose your preferred gift, then we ask you to enter your own email and your payment details.',
        'You’ll receive an email with a download button. your gift lives there!',
        'Download it and give it a new home by sending it to someone you care about.',
    ]
}

export default labels;