import * as actionTypes from "./actionTypes";

export const showModal = (options = {}) => {
    return {
        type: actionTypes.SHOW_MODAL,
        options: options
    };
};

export const closeModal = () => {
    return {
        type: actionTypes.CLOSE_MODAL
    };
};