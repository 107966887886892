import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../store/actions";
import {toast, ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

const ToastWrapper = (props) => {
    const dispatch = useDispatch()

    const removeToastMessage = React.useCallback(
        (message) => {
            dispatch(actions.removeToastMessage(message))
        },
        [dispatch]
    );

    const messages = useSelector(state => state.toast.messages);

    React.useEffect(() => {
        if (messages && messages.length) {
            console.log(messages);
            const message = messages[0];

            switch (message.type) {
                case 'success':
                    toast.success(message.title);
                    break;
                case 'error':
                    toast.error(message.title);
                    break;
                default:
                    toast(message.title);
                    break;
            }

            removeToastMessage(message);
        }

    }, [messages, removeToastMessage]);

    return (
        <>
            <ToastContainer/>
            {props.children}
        </>
    )
}

export default ToastWrapper;