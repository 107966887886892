import React from 'react';
import PropTypes from 'prop-types';

import classes from './Alert.module.css';

const Alert = (props) => {

    let alertClasses = [classes.Alert];

    switch (props.type) {
        case 'primary':
            alertClasses.push(classes.AlertPrimary);
            break;
        case 'success':
            alertClasses.push(classes.AlertSuccess);
            break;
        case 'info':
            alertClasses.push(classes.AlertInfo);
            break;
        case 'warning':
            alertClasses.push(classes.AlertWarning);
            break;
        case 'danger':
        case 'error':
            alertClasses.push(classes.AlertDanger);
            break;
        default:
            alertClasses.push('alert-primary');
            break;
    }

    if (props.className) {
        alertClasses.push(props.className);
    }

    return (
        <div
            {...props}
            className={alertClasses.join(" ")}>
            {props.children}
        </div>
    )
};

Alert.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    rounded: PropTypes.bool
}

export default Alert;