import React from "react";
import {Helmet} from "react-helmet-async";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const MetaTags = (props) => {
    const {t} = useTranslation();

    return (
        <Helmet>
            <title>{t(`${props.path}.title`)}</title>
            <meta name="description" content={t(`${props.path}.description`)}/>
        </Helmet>
    )
}

MetaTags.defaultProps = {
    path: 'pages.home'
}

MetaTags.propTypes = {
    path: PropTypes.string
}

export default MetaTags;