import * as actionTypes from "./actionTypes";
import {isString} from "../../support/utility";

export const pushToastMessage = (message) => {
    if (isString(message)) {
        message = {
            title: message,
            type: "error"
        }
    }

    return {
        type: actionTypes.PUSH_TOAST_MESSAGE,
        message: message
    };
};

export const popToastMessage = (messageId) => {
    return {
        type: actionTypes.POP_TOAST_MESSAGE,
        messageId: messageId
    };
};

export const removeToastMessage = (message) => {
    return {
        type: actionTypes.POP_TOAST_MESSAGE,
        message: message
    };
};