import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

import Aux from "../../../hoc/Aux/Aux";

import Spinner from "../Spinner/Spinner";
import classes from './Button.module.css';

const button = (props) => {

    let buttonClasses = [classes.Button];

    switch (props.buttonType) {
        case 'primary':
            buttonClasses.push(classes.ButtonPrimary);
            break;
        case 'secondary':
            buttonClasses.push(classes.ButtonSecondary);
            break;
        case 'success':
            buttonClasses.push(classes.ButtonSuccess);
            break;
        case 'warning':
            buttonClasses.push(classes.ButtonSuccess);
            break;
        case 'danger':
            buttonClasses.push(classes.ButtonDanger);
            break;
        case 'info':
            buttonClasses.push(classes.ButtonInfo);
            break;
        case 'light':
            buttonClasses.push(classes.ButtonLight);
            break;
        case 'dark':
            buttonClasses.push(classes.ButtonDark);
            break;
        case 'plain':
            buttonClasses.push(classes.ButtonPlain);
            break;
        default:
            buttonClasses.push(classes.ButtonPlain);
            break;
    }

    if (props.className) {
        const className = props.className;
        if (className.includes("absolute") && className.includes("top-") && className.includes("bottom-")) {
            buttonClasses.push(classes.ButtonAbsoluteHeight);
        }

        if (!className.includes("px-") && !className.includes("pl-")) {
            buttonClasses.push("pl-1.5");
        }

        if (!className.includes("px-") && !className.includes("pr-")) {
            buttonClasses.push("pr-1.5");
        }

        if (!className.includes("py-") && !className.includes("pt-")) {
            buttonClasses.push("pt-2");
        }

        if (!className.includes("py-") && !className.includes("pb-")) {
            buttonClasses.push("pb-2");
        }
    }

    if (props.rounded) {
        buttonClasses.push(classes.RoundedButton);
    }

    if (props.fit) {
        buttonClasses.push(classes.FittedButton);
    }

    if (props.tooltip) {
        buttonClasses.push('tooltip');
    }

    let icon = null;

    if (props.icon) {
        buttonClasses.push(classes.ButtonIcon);
        icon = props.icon;
    }

    let children = (
        <Aux>
            {icon}
            {props.children}
        </Aux>
    );

    if (props.loading) {
        buttonClasses.push(classes.ButtonLoading);
        children = <Spinner/>;
    }

    if (props.to) {
        if (props.to.startsWith('http')) {
            return (
                <a
                    href={props.to}
                    title={props.title ? props.title : props.tooltip}
                    target={props.target}
                    className={buttonClasses.join(" ")}
                    {
                        ...(props.target && {
                            target: props.target,
                            rel: props.rel
                        })
                    }
                >
                    {children}
                </a>
            )
        }

        return (
            <NavLink
                to={props.to}
                title={props.title ? props.title : props.tooltip}
                className={buttonClasses.join(" ")}>
                {children}
            </NavLink>
        )
    }

    buttonClasses.push(props.className);

    return (
        <button disabled={props.loading || props.disabled} type={props.type} title={props.title ? props.title : props.tooltip}
                onClick={(e) => props.onClick(e)} className={buttonClasses.join(" ")}>
            {children}
        </button>
    )
};

button.propTypes = {
    buttonType: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    rounded: PropTypes.bool,
    fit: PropTypes.bool,
    target: PropTypes.string,
    rel: PropTypes.string
}

button.defaultProps = {
    type: 'button',
    buttonType: 'plain',
    loading: false,
    disabled: false,
    rounded: false,
    fit: false,
    onClick: (_) => {
    }
}

export default button;