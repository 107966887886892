const social = {
    facebook: {
        appId: '432452614229577',
        redirectUrl: 'https://app.tawazonapp.com/auth/social/facebook',
        scope: [
            "email"
        ]
    },
    apple: {
        clientId: 'com.tawazonapp.website',
        redirectUrl: `https://${window.location.host}/auth/social/apple`,
        scope: [
            'name',
            'email'
        ],
    },
    google: {
        clientId: '893970546704-0eot6o0g3v958k7rb00e4hoq3i40q19f.apps.googleusercontent.com',
        redirectUrl: 'https://app.tawazonapp.com/auth/social/google',
        scope: [
            'email',
            'profile',
            'openid'
        ],
    }
}

export default social;