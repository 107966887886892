import app from './app';
import auth from './auth';

import {get as lodashGet} from 'lodash';

const index = {
    app: app,
    auth: auth,
}

export const get = (key) => {
    if (!key) {
        return key;
    }
    const tokens = key.split('::');
    if (tokens.length === 1) {
        return lodashGet(index, tokens[0]) ?? key;
    }

    const newKey = tokens.join('.');
    return lodashGet(index, newKey) ?? key;
}

export default index;