import {useMutation, useQueryClient} from "react-query";

import v2Axios from "../../../axios/v2";
import {useUpdateUserInfo} from "../auth";

const ORDER = "ORDER";

export const useVerifyOrder = (props = {}) => {
    const queryClient = useQueryClient();

    return useMutation(async (data) => {
        const response = await v2Axios.post(`orders/verify`, data);
        return response.data;
    }, {
        mutationKey: "ORDER_SUCCESS",
        ...props,
        onSuccess: (data) => {
            queryClient.setQueryData(ORDER, data);

            if (typeof window !== 'undefined' && data && data.status === 'succeeded' && data.order) {
                if (typeof window.fbq !== 'undefined') {
                    window.fbq(
                        'track', 'Purchase',
                        {
                            value: data.price,
                            currency: 'USD',
                            content_type: 'product',
                            content_ids: data.order.items.map(item => item.id),
                            contents: data.order.items,
                        },
                        {eventID: `ORDER_${data.order.id}`}
                    );
                }

                if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'purchase', {
                        'value': data.price,
                        'currency': 'USD',
                        'transaction_id': `ORDER_${data.order.id}`,
                        'items': data.order.items
                    });
                }
            }

            if (props.hasOwnProperty('onSuccess')) {
                props.onSuccess(data);
            }
        },
    });
}

export const useOrderData = () => {
    const queryClient = useQueryClient();
    const reset = () => {
        queryClient.resetQueries(ORDER, {exact: true}).then(() => {
        });
    }

    return {
        data: queryClient.getQueryData(ORDER, {exact: true}),
        reset
    }
}

export const useCreateOrder = (props = {}) => {
    const queryClient = useQueryClient();

    return useMutation(async (data) => {
        const response = await v2Axios.post("orders/create", data);
        queryClient.setQueryData(ORDER, response.data);
        return response.data;
    }, {
        mutationKey: ORDER,
        ...props,
    });
}

export const useRedeemCode = (props = {}) => {
    const {update} = useUpdateUserInfo();

    return useMutation(async (data) => {
        return await v2Axios.post("orders/redeem", data);
    }, {
        mutationKey: "REDEEM_CODE",
        ...props,
        onSuccess: (response) => {
            update(response.data);

            if (props.hasOwnProperty('onSuccess')) {
                props.onSuccess(response);
            }
        },
    });
}