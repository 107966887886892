import labels from './labels';
import messages from './messages';
import pages from './pages';

const index = {
    labels: labels,
    messages: messages,
    pages: pages,
}

export default index;