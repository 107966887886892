const stripe = {
    'mode': 'sandbox',
    'live': {
        'publishable_key': 'pk_live_McnyiwSzFfT1sczF647AQqo600Z9aXp0EP',
    },
    'sandbox': {
        'publishable_key': 'pk_test_rIy9iyUj6fPryiA0T77CttJx00Zx24ZRR3',
    },
}

export default stripe;