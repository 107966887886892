import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../support/utility';

const initialState = {
    messages: [],
};

const pushMessage = (state, action) => {
    const newMessages = [
        ...state.messages
    ];

    newMessages.push(action.message);

    return updateObject(state, {
        messages: newMessages
    });
};

const popMessage = (state, action) => {
    const newMessages = [
        ...state.messages
    ];

    newMessages.shift();

    return updateObject(state, {
        messages: newMessages
    });
};

const removeMessage = (state, action) => {
    const newMessages = [
        ...state.messages
    ];

    newMessages.filter((item) => {
        return item !== action.message;
    })

    return updateObject(state, {
        messages: newMessages
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PUSH_TOAST_MESSAGE:
            return pushMessage(state, action);
        case actionTypes.POP_TOAST_MESSAGE:
            return popMessage(state, action);
        case actionTypes.REMOVE_TOAST_MESSAGE:
            return removeMessage(state, action);
        default:
            return state;
    }
};

export default reducer;