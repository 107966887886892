import React from 'react';

const singleLayout = (props) => {
    return (
        <>
            <div className={"bg-default text-sm"}>
                {props.children}
            </div>
        </>
    )
}

export default singleLayout;