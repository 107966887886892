import PropTypes from "prop-types";

import classes from './Card.module.css';

const Card = (props) => {
    const hasPadding = props.className && props.className.split(" ").find((s) => {
        return s.startsWith("p-")
    });

    const defaultPadding = hasPadding ? '' : 'p-8';

    return (
        <div
            onClick={props.onClick}
            className={`flex ${defaultPadding} w-full items-center bg-white rounded-3xl ${props.className} ${classes.Card} ${props.hasTabs ? classes.CardWithTabs: ''}`} style={props.style}>
            {props.children}
        </div>
    )
}

Card.defaultProps = {
    className: null,
    onClick: () => {}
}

Card.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    hasTabs: PropTypes.bool
}

export default Card;