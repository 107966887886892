import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import url from "../../../../services/url";

import Card from "../../../../components/ui/Card";
import DescriptionItem from "../DescriptionItem/DescriptionItem";
import Button from "../../../../components/ui/Button";

import rightImage from "../../../../assets/images/profile_right.png";
import backgroundImage from "../../../../assets/images/profile_base.png";
import classes from './DescriptionCard.module.css';

const DescriptionCard = (props) => {

    const {t} = useTranslation();
    let navigate = useNavigate();

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }

    const items = t('labels.profile_items').map((item, index) => {
        return (
            <DescriptionItem key={`DescriptionItem-${index}`} className={"py-5"} title={item}/>
        )
    });

    const onSubscribeClicked = () => {
        navigate(url.get('/profile/subscribe'))
    }

    return (
        <Card hasTabs className={`p-8 py-14 sm:p-14 md:px-8 lg:px-10 xl:px-16 2xl:px-20`} style={backgroundStyle}>
            <div className={"grid grid-cols-12 w-full"}>
                <div className={"hidden md:flex col-span-6 items-center"}>
                    <img alt={t('labels.subscription_features')} className={"w-full w-3/4"} src={rightImage}/>
                </div>
                <div className={`col-span-12 md:col-span-6 flex items-center ${classes.Item}`}>
                    <div className={"flex flex-col w-full py-5"}>
                        <h1 className={"text-2xl"}>{t('labels.tawazon_premium')}</h1>
                        <h2 className={`${classes.ItemSubtitle} py-2.5`}>{t('messages.your_own_paid_subscription_plan')}</h2>

                        {items}

                        <Button
                            className={"my-3"}
                            buttonType={"primary"}
                            onClick={onSubscribeClicked}
                            rounded>
                            {t('labels.subscribe')}
                        </Button>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default DescriptionCard;