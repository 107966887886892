const labels = {
    'name_required': 'الاسم إجباري',
    'email_required': 'البريد الإلكتروني إجباري',
    'email_invalid': 'البريد الإلكتروني غير صحيح',
    'current_password_required': 'كلمة المرور الحالية إجبارية',
    'password_required': 'كلمة المرور إجبارية',
    'confirm_password_required': 'تأكيد كلمة المرور إجباري',
    'invalid_confirm_password': 'تأكيد كلمة المرور يجب أن تطابق كلمة المرور',
    'delete_account': 'سوف يتم حذف الحساب بعد شهر من الآن، لإيقاف ذلك قم بتسجيل الدخول قبل التاريخ المحدد',
}

export default labels;