import {getLanguageCode} from "../../support/utility";

const ROUTE_LANGUAGE_CODE = 'ar';

const get = (url) => {
    const languageCode = getLanguageCode();

    if (languageCode === ROUTE_LANGUAGE_CODE) {
        return url;
    }

    if (url.startsWith(`${languageCode}/`)) {
        url = url.substring(languageCode.length + 1);
    }

    if (url.startsWith(`/${languageCode}/`)) {
        url = url.substring(languageCode.length + 2);
    }

    if (url.startsWith('/')) {
        url = url.substring(1);
    }

    return `/${languageCode}/${url}`;
}

const getWebsiteUrl = (url = "") => {
    let path = get(url);

    if (path === "/en/") {
        path = "/en/home";
    }

    if (path && path !== "/" && !path.startsWith('/')) {
        path = `/${path}`;
    }

    return "https://tawazonapp.com" + path;
}

const urlObject = {get, getWebsiteUrl};
export default urlObject;