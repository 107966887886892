const labels = {
    home: {
        title: "Tawazon | The Mindfulness app you've been waiting for!",
        description: "The #1 Mindfulness & Meditation App in Arabic Language. Start your journey towards a balanced life with Tawazon.",
    },
    login: {
        title: "Tawazon | Login",
        description: "Login to your own customized account",
    },
    profile: {
        title: "Tawazon | Profile",
        description: "Manage your profile, subscriptions and settings",
    },
    account: {
        title: "Tawazon | Account",
        description: "Manage your account and settings",
    },
    subscribe: {
        title: "Tawazon | Subscribe",
        description: "Subscribe to Tawazon Premium and enjoy everything the app has to offer",
    },
    buyGift: {
        title: "Tawazon | Buy a gift",
        description: "Buy a gift and give it to your loved ones",
    },
    redeem: {
        title: "Tawazon | Redeem",
        description: "Redeem your code to enjoy Tawazon premium",
    },
    resetPassword: {
        title: "Tawazon | Reset Password",
        description: "Reset your password with a confirmation code",
    }
}

export default labels;