const labels = {
    'name_required': 'Name required',
    'email_required': 'Email required',
    'email_invalid': 'Email invalid',
    'current_password_required': 'Current password required',
    'password_required': 'Password required',
    'confirm_password_required': 'Password confirmation required',
    'invalid_confirm_password': 'Password confirmation invalid',
    'delete_account': 'Your account will be deleted after one month, to stop this from happening simply login before the due date',
}

export default labels;