import React from 'react';
import {Route} from "react-router-dom";
import url from '../services/url';

const ProfilePage = React.lazy(() => {
    return import('../containers/Profile/ProfilePage');
});

const ProfileAccountPage = React.lazy(() => {
    return import('../containers/Profile/Account/AccountPage');
});

const ProfileSubscribePage = React.lazy(() => {
    return import('../containers/Profile/Subscribe/SubscribePage');
});

const ProfileSubscribeSuccess = React.lazy(() => {
    return import('../containers/Profile/Subscribe/Success/SubscribeSuccessPage');
});

export const profileRoutes = [
    <Route key={"tawazon::profile.account"} path={url.get("/profile/subscribe/:campaignId")} exact element={<ProfileSubscribePage/>}/>,
    <Route key={"tawazon::profile.account"} path={url.get("/profile/subscribe/success")} exact element={<ProfileSubscribeSuccess/>}/>,
    <Route key={"tawazon::profile.account"} path={url.get("/profile/subscribe")} exact element={<ProfileSubscribePage/>}/>,
    <Route key={"tawazon::profile.account"} path={url.get("/profile/account")} exact element={<ProfileAccountPage/>}/>,
    <Route key={"tawazon::profile.index"} path={url.get("/profile")} exact element={<ProfilePage/>}/>,
];