import {useQuery} from "react-query";
import axios from "../../axios";

export const FETCH_APPLICATION_INFO = "FETCH_APPLICATION_INFO";

export const useFetchApplicationInfo = (props = {}) => {
    return useQuery(FETCH_APPLICATION_INFO, async () => {
        let response = await axios.get("app/info");
        return response.data;
    }, {
        enabled: false,
        ...props
    });
}