import React from 'react';
import Spinner from 'react-spinkit';

const LoadingPage = (props) => {
    return (
        <div className={"min-h-screen min-w-screen flex items-center justify-center"}>
            <div className={"flex flex-col items-center"}>
                <Spinner fadeIn={'none'} name='double-bounce' color={"#A59BB8"}/>
            </div>
        </div>
    );
}

export default LoadingPage;