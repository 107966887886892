import axios from 'axios';
import {setupInstance} from "./utility";

const instance = axios.create({
    baseURL: 'https://api.tawazonapp.com/api/v1/'
});

instance.interceptors.request.use(req => {
    if (req.method === 'post') {
        req.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
    }

    return req;
});

setupInstance(instance);

export default instance;