import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import url from '../../services/url';

const Footer = (props) => {
    const {t} = useTranslation();

    const hasPadding = props.className && props.className.split(" ").find((s) => {
        return s.startsWith("p-")
    });

    const defaultPadding = hasPadding ? '' : 'p-14';

    return (
        <div className={`flex justify-center text-center text-gray-400 text-xs ${defaultPadding} ${props.className}`}>
            <p className={"whitespace-pre-line leading-6"}>
                {t('messages.login_terms_message')}
                <a rel="noreferrer" href={url.getWebsiteUrl("/pages/terms-and-conditions")} target={"_blank"} className={"mx-1 inline-block text-gray-900"}>
                    {t('labels.terms')}
                </a>
            </p>
        </div>
    );
}

Footer.propTypes = {
    className: PropTypes.string
}

export default Footer;