const ROUTE_LANGUAGE_CODE = 'ar';
const LANGUAGES = {
    ar: {
        name: "Arabic",
        dir: "rtl",
        code: "ar"
    },
    en: {
        name: "English",
        dir: "rtl",
        code: "en"
    }
}

const languageCode = () => {
    return document.documentElement.lang || 'ar';
}

const routeLanguageCode = () => {
    if (languageCode() === ROUTE_LANGUAGE_CODE) {
        return null;
    }

    return languageCode();
}

const languages = () => {
    return LANGUAGES;
}

const switchUrl = (languageCode, url = null) => {
    let anchorElement = document.createElement('a');
    anchorElement.href = url;

    if (!url) {
        url = window.location.pathname;
        anchorElement.href = window.location.href;
    }

    if (!url) {
        return url;
    }

    const params = new URLSearchParams(anchorElement.search);
    if (params.has("redirect")) {
        params.set("redirect", switchUrl(languageCode, params.get("redirect")));
    }

    for (let key in LANGUAGES) {
        if (LANGUAGES.hasOwnProperty(key)) {
            if (url.startsWith(`${key}/`)) {
                url = url.substring(key.length + 1);
            }

            if (url.startsWith(`/${key}/`)) {
                url = url.substring(key.length + 2);
            }
        }
    }

    if (url.startsWith('/')) {
        url = url.substring(1);
    }

    if (params && params.toString()) {
        url = `${url}?${params.toString()}`;
    }

    if (languageCode === ROUTE_LANGUAGE_CODE) {
        return `/${url}`;
    }

    return `/${languageCode}/${url}`;
}

const translateObject = {routeLanguageCode, languageCode, languages, switchUrl};
export default translateObject;