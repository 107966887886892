import * as Yup from 'yup';
import {get as lodashGet, set as lodashSet} from 'lodash';
import React from "react";
import i18n from '../i18n';

export const setCookie = (cname, cValue, expirationDays) => {
    const d = new Date();
    d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cValue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
}

export const isRTL = () => {
    return document.documentElement.dir === 'rtl';
}

export const getLanguageCode = () => {
    return document.documentElement.lang || 'ar';
}

export const getLanguageCodes = () => {
    return [
        "ar", "en"
    ];
}

export const getResponseErrorMessage = (e) => {
    const message = i18n.t('messages.network_error');

    if (e && e.response && e.response.data) {
        if (e.response.data.message) {
            return e.response.data.message;
        }
    }

    return message;
}

export const isString = (string) => {
    return typeof string === 'string' || string instanceof String;
}

export const chars = (string, limit = 100, end = '...') => {
    if (!isString(string)) {
        string = "" + string;
    }
    if (string.length <= limit) {
        return string;
    }

    return string.substring(0, limit) + end;
}

export const isFunction = (functionToCheck) => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export const inArray = (array, value) => {
    return array.indexOf(value) >= 0;
}

export const SubmitErrorHandler = ({isSubmitting, isValid, onSubmitError}) => {
    React.useEffect(() => {
        if (isSubmitting && !isValid) {
            onSubmitError();
        }
    }, [isSubmitting, isValid, onSubmitError]);

    return null;
};

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const appendObject = (oldObject, newObject) => {
    let object = {
        ...oldObject
    }

    for (let property in newObject) {
        if (newObject.hasOwnProperty(property)) {
            if (!object.hasOwnProperty(property)) {
                object[property] = newObject[property];
            } else {
                object[property] = appendObject(oldObject[property], newObject[property]);
            }
        }
    }

    return object;
}

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}

class YupRules {
    constructor(builder) {
        this.builder = builder;
    }
}

export const buildYupRules = (rules) => {
    let builder = Yup.string();
    if (rules.required) {
        builder = builder.required(rules.required.message ? rules.required.message : "Field is required!");
    }

    if (rules.email) {
        builder = builder.email(rules.email.message ? rules.email.message : "Email is invalid!");
    }

    if (rules.confirmation) {
        const message = rules.confirmation.message ? rules.confirmation.message : 'Passwords must match';
        builder = builder.test('passwords-match', message, function (value) {
            let refValue = lodashGet(this.options.context, rules.confirmation.ref);
            if (!refValue) {
                refValue = lodashGet(this.options.parent, rules.confirmation.ref);
            }
            return refValue === value || (!refValue && !value);
        });
    }

    if (rules.total_sum) {
        const message = rules.total_sum.message ? rules.total_sum.message : 'Total sum is invalid';
        const totalSum = parseFloat(rules.total_sum.value + "");
        builder = builder.test('total_sum', message, function (value) {

            let sumSoFar = parseFloat(value + "");
            for (let i = 0; i < rules.total_sum.ref.length; i++) {
                let singleRef = rules.total_sum.ref[i];
                let refValue = lodashGet(this.options.context, singleRef);
                sumSoFar += parseFloat(refValue + "");
            }

            return sumSoFar === totalSum;
        });
    }

    if (rules.array) {
        if (!rules.array.rules || rules.array.rules.length <= 0) {
            return null;
        }

        builder = Yup.array().of(buildYupScheme(rules.array.rules));
    }

    return new YupRules(builder);
}

const buildYupSchemeHelper = (objectToReturn) => {
    for (let property in objectToReturn) {
        if (objectToReturn.hasOwnProperty(property)) {
            if (objectToReturn[property] instanceof YupRules) {
                objectToReturn[property] = objectToReturn[property].builder;
            } else {

                const returnedObject = buildYupSchemeHelper(objectToReturn[property]);

                objectToReturn[property] = Yup.object().shape({
                    ...returnedObject
                });
            }
        }
    }

    return objectToReturn;
}

export const buildYupScheme = (fieldRules) => {
    let objectToReturn = {};
    for (let filedName in fieldRules) {
        if (fieldRules.hasOwnProperty(filedName)) {
            const singleFieldRules = fieldRules[filedName];
            if (singleFieldRules) {
                lodashSet(objectToReturn, filedName, buildYupRules(singleFieldRules))
            }
        }
    }

    objectToReturn = buildYupSchemeHelper(objectToReturn);

    return Yup.object().shape({
        ...objectToReturn
    });
}