const labels = {
    'breath_and_start_over': 'تنفس، وابدأ من جديد',
    'log_into_your_account': 'Login into your account',
    'login_terms_message': 'By continuing you agree to our',
    'dont_have_an_account': 'Don\'t have an account?',
    'already_have_an_account': 'Already registered?',
    'create_a_free_account': 'Create your free account',
    'forgot_your_password': 'Forgot your password?',
    'password_reset': 'Reset your password',
    'your_own_paid_subscription_plan': 'Your own subscription plan',
    'your_profile_and_account_settings': 'Profile & account settings',
    'subscribe_items': {
        'integrated_library': 'Integrated meditation library for body and soul',
        'new_meditation_weekly': 'New meditation every week',
        'children_library': 'Specialized meditation library for children!',
        'specialized_program': 'Unique and Specialized meditation programs',
    },
    'subscription_footer_message': 'Tawazon subscription gives you access to all app features!',
    'payment_via_stripe': 'Powered by Stripe!',
    'you_are_about_to_purchase_the_following_item': 'You will purchase the following item',
    'are_you_sure': 'Are you sure?',
    'loading_your_checkout_page': 'We are preparing your checkout page!',
    'processing_your_order': 'We are processing your order!',
    'unable_to_fetch_order_info': 'An error occurred while preparing your order, please try again!',
    'please_contact_support_with_this_information': 'Please contact support with the following information',
    'to_contact_support': 'To contact support',
    'click_on_item_for_easier_access': 'Click on the email to open your mail client!',
    'fetching_subscription_types': 'We are preparing available subscriptions!',
    'preparing_your_subscription': 'We are preparing your subscription!',
    'preparing_your_order': 'We are preparing your order!',
    'preparing_payment_form': 'We are preparing your checkout form!',
    'subscription_expires_on': 'Subscription expires on',
    'subscription_cancelled_on': 'Subscription cancelled on',
    'cancellation_note': 'Note: cancelling subscription, will keep your access active until the subscription period is over',
    'you_are_about_to_cancel_your_subscription': 'You are about to cancel your subscription!',
    'network_error': 'Network error',
    'an_expected_error_occurred': 'Unknown error',
    'we_are_processing_your_order': 'We are processing your order, you will receive an email once the order is completed!',
    'order_cancelled_with_redirecting': 'Your order is cancelled, click here to try again',
    'payment_successful': 'Success! You should be able to access all app features now! For any problems with your access please contact support!',
    'verifying_your_purchase': 'We are verifying your purchase!',
    'good_evening': 'Good evening',
    'close_your_eyes_to_see': 'Close your eyes to see ...',
    'start_your_journey_now': 'Start your journey now!',
    'fill_information_below': 'Enter your information below',
    'cancellation_content': 'Are you sure you want to cancel?',
    'redeem_code_required': 'Code required',
    'something_went_wrong': 'Something went wrong',
    'redeem_code': 'إشتراك خاص بك فور تفعيل الرمز',
    'click_here_to_try_again': 'Click here to try again.',
    'error_while_verifying_your_order': 'An error occurred while verifying your order!',
    'contact_support_by_email': 'You can contact support by sending an email to',
    'payment_intent_status': 'Your payment has a status of',
    'order_verified': 'Success! You should receive an email shortly with details about your purchase! If you can not find it please check your spam folder. For any problems please contact support!',
    'redeem_premium_notice': 'You are a premium user and you can see your subscription info inside your Profile',
    'see_subscription_details': 'Click here to view your subscription details',
    'quantity_required': 'Quantity is required',
    'tracking_id_notice_1': 'Your order tracking ID is',
    'tracking_id_notice_2': 'Please keep track of it and provide it to our support when needed'
}

export default labels;