import React, {Suspense, useEffect} from 'react';
import {matchRoutes, Navigate, Route, Routes, useLocation, useMatch} from "react-router-dom";

import BaseLayout from "./hoc/Layouts/BaseLayout/BaseLayout";
import SingleLayout from "./hoc/Layouts/SingleLayout/SingleLayout";

import ProfilePage from "./containers/Profile";
import LoadingPage from './containers/LoadingPage';
import ErrorPage from "./containers/Error/ErrorPage";

import {profileRoutes} from "./routes";
import url from "./services/url";

import './App.css';
import {useFetchApplicationInfo, useFetchAuth} from "./hooks/query";

const LoginPage = React.lazy(() => {
    return import('./containers/Login');
});

const ResetPasswordPage = React.lazy(() => {
    return import('./containers/ResetPassword');
});

const RedeemPage = React.lazy(() => {
    return import('./containers/Redeem');
});

const BuyGiftPage = React.lazy(() => {
    return import('./containers/BuyGift');
});

const App = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const {
        refetch: fetchApplicationInfo,
        error: fetchApplicationInfoError,
        isFetching: loadingApplicationInfo
    } = useFetchApplicationInfo();
    const {data, refetch: fetchAuth, error: fetchUserError, isFetching: loadingUserInfo} = useFetchAuth();

    const user = data ? data.user : null;

    const shouldUseSingleLayout = !!matchRoutes([
        {path: url.get('/redeem')},
        {path: url.get('/makif-a/redeem')},
        {path: url.get('/gifts/buy')},
        {path: url.get('/gifts/buy/:id')},
        {path: url.get('/gifts/purchase')},
        {path: url.get('/gifts/purchase/:id')}
    ], location);

    const noAuthRequiredMatch = useMatch(url.get('/reset-password/:confirmationCode'));
    const shouldFetchUser = !noAuthRequiredMatch;

    useEffect(() => {
        fetchApplicationInfo();
    }, [fetchApplicationInfo])

    useEffect(() => {
        if (!user) {
            if (shouldFetchUser) {
                fetchAuth();
            }
        }

    }, [user, fetchAuth, shouldFetchUser]);

    let routes = (
        <Routes>
            {profileRoutes}

            <Route path={url.get("/gifts/buy")} exact element={<BuyGiftPage/>}/>
            <Route path={url.get("/gifts/buy/:id")} exact element={<BuyGiftPage/>}/>
            <Route path={url.get("/gifts/purchase")} exact element={<BuyGiftPage/>}/>
            <Route path={url.get("/gifts/purchase/:id")} exact element={<BuyGiftPage/>}/>
            <Route path={url.get("/redeem")} exact element={<RedeemPage/>}/>
            <Route path={url.get("/makif-a/redeem")} exact element={<RedeemPage campaign={"makif-a"}/>}/>
            <Route path={url.get("/homepage")} exact element={<ProfilePage/>}/>
            <Route path="*" element={<ProfilePage/>}/>
        </Routes>
    );

    if (loadingApplicationInfo && !fetchApplicationInfoError) {
        return (
            <SingleLayout>
                <LoadingPage/>
            </SingleLayout>
        );
    }

    if (fetchApplicationInfoError) {
        return (
            <SingleLayout>
                <ErrorPage error={fetchApplicationInfoError}/>
            </SingleLayout>
        );
    }

    if (
        shouldFetchUser &&
        ((!fetchUserError && !user) || loadingUserInfo)
    ) {
        return (
            <SingleLayout>
                <LoadingPage/>
            </SingleLayout>
        );
    }

    if (!user) {
        routes = (
            <Routes>
                <Route path={url.get("/redeem")} exact element={<RedeemPage/>}/>
                <Route path={url.get("/gifts/buy")} exact element={<BuyGiftPage/>}/>
                <Route path={url.get("/gifts/buy/:id")} exact element={<BuyGiftPage/>}/>
                <Route path={url.get("/gifts/purchase")} exact element={<BuyGiftPage/>}/>
                <Route path={url.get("/gifts/purchase/:id")} exact element={<BuyGiftPage/>}/>
                <Route path={url.get("/reset-password/:confirmationCode")} exact element={<ResetPasswordPage/>}/>
                <Route path={url.get("/profile/login")} exact element={<LoginPage/>}/>
                <Route
                    path="*"
                    element={<Navigate to={url.get("/profile/login?redirect=" + location.pathname)}/>}
                />
            </Routes>
        );

        return (
            <SingleLayout>
                <Suspense fallback={<LoadingPage/>}>{routes}</Suspense>
            </SingleLayout>
        );
    }

    if (shouldUseSingleLayout) {
        return (
            <SingleLayout>
                <Suspense fallback={<LoadingPage/>}>{routes}</Suspense>
            </SingleLayout>
        );
    }

    return (
        <BaseLayout>
            <Suspense fallback={<LoadingPage/>}>{routes}</Suspense>
        </BaseLayout>
    );
}

export default App;
