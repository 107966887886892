const labels = {
    'breath_and_start_over': 'تنفس، وابدأ من جديد',
    'log_into_your_account': 'سجل الدخول إلى حسابك',
    'login_terms_message': 'من خلال الاستمرار في استخدام توازن،\nفإنك توافق على',
    'dont_have_an_account': 'لا تملك حساب؟',
    'already_have_an_account': 'هل لديك حساب؟',
    'create_a_free_account': 'انشاء حساب مجاني على توازن',
    'forgot_your_password': 'هل نسيت كلمة المرور؟',
    'password_reset': 'إعادة تعيين كلمة المرور',
    'your_own_paid_subscription_plan': 'خطة إشتراك مدفوعة خاصة بك',
    'your_profile_and_account_settings': 'ملفك الشخصي وإعدادات حسابك',
    'subscribe_items': {
        'integrated_library': 'مكتبة متكاملة من التأمل الموجه للجسد والروح',
        'new_meditation_weekly': 'اسبوعياً نطلق محتوى جديد!',
        'children_library': 'محتوى مخصص للأطفال والناشئين',
        'specialized_program': 'محتوى مخصص لفهم العلاقة بين الجسد والروح',
    },
    'subscription_footer_message': 'الإشتراك بتَوازُن يعطيك الإمكانية لاستخدام كافة مزايا التطبيق.',
    'payment_via_stripe': 'الدفع سيتم عن طريق Stripe',
    'you_are_about_to_purchase_the_following_item': 'سوف تقوم بشراء العنصر التالي',
    'are_you_sure': 'هل أنت متأكد؟',
    'loading_your_checkout_page': 'جاري تحضير صفحة الدفع!',
    'processing_your_order': 'جاري معالجة طلبك',
    'unable_to_fetch_order_info': 'حدث خطأ أثناء معالجة طلبك',
    'please_contact_support_with_this_information': 'الرجاء التواصل مع الدعم وإرسال هذه المعلومات',
    'to_contact_support': 'للتواصل مع الدعم',
    'click_on_item_for_easier_access': 'تستطيع الضغط على عنوان البريد لإرسال بريد جديد',
    'fetching_subscription_types': 'جاري تحضير الإشتراكات',
    'preparing_your_subscription': 'جاري تحضير الإشتراك الخاص بك',
    'preparing_your_order': 'جاري تحضير الطلب الخاص بك',
    'preparing_payment_form': 'جاري تحضير نموذج الدفع',
    'subscription_expires_on': 'ينتهي الإشتراك في',
    'subscription_cancelled_on': 'تم إلغاء الإشتراك في',
    'cancellation_note': 'ملاحظة: إلغاء الإشتراك يوقف عمليات الدفع المبرمجة لكن الإشتراك الفعلي سوف يبقى فعال حتى تاريخ إنتهاء الإشتراك',
    'you_are_about_to_cancel_your_subscription': 'أنت على وشك إلغاء الإشتراك',
    'network_error': 'خطأ في الاتصال',
    'an_expected_error_occurred': 'حدث خطأ غير متوقع',
    'we_are_processing_your_order': 'يجري معالجة طلبك، سوف يتم إرسال بريد إلكتروني لك فور إتمام عملية الدفع',
    'order_cancelled_with_redirecting': 'تم إلغاء طلبك، إضغط على هذه الرسالة من أجل إعادة عملية الدفع!',
    'payment_successful': 'تمت عملية الدفع بنجاح، في حال عدم تفعيل الخدمة خلال بضعة دقائق يرجى التواصل مع فريق الدعم الفني!',
    'verifying_your_purchase': 'جاري التحقق من عملية الشراء الخاصة بك!',
    'good_evening': 'مساء الخير',
    'close_your_eyes_to_see': 'أغمض عينيك لترى ..',
    'start_your_journey_now': 'إبدأ رحلتك الآن',
    'fill_information_below': 'يرجى إدخال معلوماتك بالأسفل',
    'cancellation_content': 'أنت على وشك إلغاء الاشتراك، هل أنت متأكد؟',
    'redeem_code_required': 'رمز التفعيل إجباري',
    'something_went_wrong': 'حصل خطأ ما',
    'redeem_code': 'إشتراك خاص بك فور تفعيل الرمز',
    'click_here_to_try_again': 'اضغط هنا للمجاولة مجدداً',
    'error_while_verifying_your_order': 'حدث خطأ أثناء التحقق من عملية الشراء',
    'contact_support_by_email': 'تستطيع التواصل مع الدعم من خلال إرسال بريد إلكتروني إلى',
    'payment_intent_status': 'حالة طلب الشراء الخاص بك هي',
    'order_verified': 'تمت عملية الدفع بنجاح، سوف يصلك بريد إلكتروني يحتوي كافة التفاصيل خلال بضعة دقائق! يرجى تفقد الرسائل المزعجة وملفات السبام. في حال حدوث أي خطأ يرجى التواصل مع فريق الدعم الفني!',
    'redeem_premium_notice': 'أنت مشترك بريميوم! تستطيع مشاهدة تفاصيل إشتراكك داخل ملفك الشخصي',
    'see_subscription_details': 'اضغط هنا لمشاهدة تفاصيل الإشتراك',
    'quantity_required': 'الكمية مطلوبة',
    'tracking_id_notice_1': 'رقم تتبع طلبك هو',
    'tracking_id_notice_2': 'قم بالحفاظ عليه وإرفاقه في اي طلب لمراجعة طلبك'
}

export default labels;