const labels = {
    'name': 'الاسم',
    'email': 'البريد الإلكتروني',
    'current_password': 'كلمة المرور الحالية',
    'password': 'كلمة المرور',
    'confirm_password': 'تأكيد كلمة المرور',
    'log_in': 'تسجيل الدخول',
    'sign_up': 'سجل',
    'terms': 'شروطنا',
    'click_here': 'اضغط هنا',
    'submit': 'ارسال',
    'new_password': 'كلمة المرور الجديدة',
    'profile': 'الملف الشخصي',
    'settings': 'الإعدادت',
    'log_out': 'تسجيل الخروج',
    'tawazon_premium': 'تَوازُن بريميوم',
    'profile_and_account': 'الملف الشخصي والحساب',
    'yes': 'نعم',
    'no': 'لا',
    'cancel': 'إلفاء',
    'base_account': 'حساب أساسي',
    'premium_account': 'حساب بريميوم',
    'cancel_subscription': 'إلغاء الإشتراك',
    'subscribe': 'إشترك',
    'or': 'أو',
    'edit': 'تعديل',
    'close': 'إغلاق',
    'profile_items': [
        'مكتبة متكاملة من التأمل الموجه للجسد والروح',
        'تأمل جديد كل أسبوع',
        'مكتبة تأمل مخصصة للأطفال والناشئين',
        'مكتبة موسيقى خاصة',
        'برامج تأمل متنوعة',
    ],
    'subscribe_items': {
        'integrated_library': 'لأول مرة باللغة العربية',
        'new_meditation_weekly': 'تأمل جديد كل أسبوع',
        'children_library': 'مكتبة تأمل مخصصة للأطفال والناشئين',
        'specialized_program': 'برنامج متخصص',
    },
    'subscription_features': 'خصائص الإشتراك',
    'current_subscription': 'اشتراكك الحالي',
    'cancellation_title': 'يؤسفنا رؤيتك تغادر 😔',
    'redeem_your_gift': 'قم بتفعيل هديتك',
    'ender_your_code': 'قم بإدخال رمز التفعيل',
    'redeem': 'تفعيل',
    'partner_logo': 'شعار الشريك',
    'google_app_link': 'رابط التحميل للأندرويد',
    'apple_app_link': 'رابط التحميل لأجهزة آبل',
    'password_reset': 'إعادة تعيين كلمة المرور',
    'redeem_code': 'تفعيل رمز الإشتراك',
    'discount': 'خصم',
    'total': 'المجموع',
    'discount_code': 'كود الخصم',
    'activate_code': 'تفعيل كود الخصم',
    'add': 'إضافة',
    'gift_page_title': 'هدية توازن لأحبابك',
    'received_a_gift': 'تلقيت هدية؟',
    'quantity': 'الكمية',
    'continue': 'الاستمرار',
    'purchase': 'شراء',
    'try_again': 'حاول جدداً',
    'buy_gift': 'شراء هدية',
    'gift_page_slogan_1': 'أظهـر اهتمامك',
    'gift_page_slogan_2': 'بشخص عزيز اليوم',
    'gift_page_slogan_3': 'اختار الهدية وخلي الباقي علينا',
    'gift_page_steps_title': 'الموضوع ابسط مما تتصور:',
    'gift_page_steps': [
        'اختر الهدية المناسبة, ثم ادخل بريدك الالكتروني الخاص وقم باختيار طريقة الدفع الأنسب لك',
        'سنقوم بارسال رسالة الى بريدك الالكتروني فيها رابط تحميل ملف الهدية',
        'حمل الملف وارسله لمن تحب ليعيشوا تجربة مميزة مع توازن',
    ]
}

export default labels;