import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import labelsEn from './locales/en';
import labelsAr from './locales/ar';

// the translations
const resources = {
    en: labelsEn,
    ar: labelsAr,
};

const pathname = window.location.pathname;
if (pathname && pathname.startsWith("/en")) {
    document.documentElement.lang = "en";
    document.documentElement.dir = "ltr";
} else {
    document.documentElement.lang = "ar";
    document.documentElement.dir = "rtl";
}

const htmlLang = document.documentElement.lang || "ar";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: htmlLang,
        ns: ["app"],
        defaultNS: "app",
        returnObjects: true,
        nsSeparator: '::',
        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        fallbackLng: ['ar', 'en']
    });

export default i18n;