const ResponsiveGrid = (props) => {
    const responsiveClasses = "sm:col-span-10 sm:col-start-2 lg:col-span-8 lg:col-start-3";
    const LargerResponsiveClasses = "xl:col-span-7 xl:col-start-4 2xl:col-span-6 2xl:col-start-4";

    return (
        <div className={"grid grid-cols-12 xl:grid-cols-13 2xl:grid-cols-12 h-full"}>
            <div className={`col-span-12 ${responsiveClasses} ${LargerResponsiveClasses} flex flex-col items-center justify-between`}>
                {props.children}
            </div>
        </div>
    )
}

export default ResponsiveGrid;