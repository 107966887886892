import React from 'react';
import PropTypes from "prop-types";

export const TabList = (props) => {
    return (
        <div className={`grid grid-cols-12 sm:flex w-full gap-2 ${props.className}`}>
            {props.children}
        </div>
    )
}

TabList.propTypes = {
    className: PropTypes.string
}

export default TabList;