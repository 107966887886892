import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as actions from "../../store/actions";

import classes from './Modal.module.css';

const Modal = () => {
    const dispatch = useDispatch()

    const closeModal = React.useCallback(
        () => {
            dispatch(actions.closeModal())
        },
        [dispatch]
    );

    const defaultColumnClasses = 'col-span-12 mx-5 sm:mx-0 sm:col-span-13 sm:col-start-2 md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-4 2xl:col-span-4 2xl:col-start-5';

    const modals = useSelector(state => state.modal.modals);
    return modals.map((modal, index) => {
        const isOpen = modal.isOpen;
        const modalOptions = modal.options;
        const modalContent = modalOptions ? modalOptions.content : null;
        const columnClass = modalOptions && modalOptions.columnClass ? modalOptions.columnClass : defaultColumnClasses;
        const contentToRender = isOpen ? modalContent : null;
        const wrapperClass = isOpen ? "visible" : "hidden hide";

        return (
            <div key={`modal-${index}`} className={wrapperClass}>
                <div style={{
                    zIndex: 1050 + (1000 * index + 1)
                }} className={classes.Modal}>
                    <div className={"grid grid-cols-12 sm:grid-cols-14 md:grid-cols-12 h-full"}>
                        <div className={`${columnClass} flex flex-col items-center justify-center`}>
                            <div className={`rounded-3xl bg-white w-full relative`}>
                                <div>
                                    {contentToRender}
                                </div>

                                <i
                                    className={"ti-close cursor-pointer absolute top-5 start-5"}
                                    onClick={closeModal}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    zIndex: 1050 + (1000 * index)
                }} className={classes.ModalBackdrop}/>
            </div>
        )
    });
}

export default Modal;