import React from 'react';

import Master from '../../Master/Master';
import Modal from "../../../components/Modal/Modal";

import classes from './BaseLayout.module.css'

const BaseLayout = props => {
    let mainClasses = [classes.AppContent];
    mainClasses.push("px-8");

    return (
        <>
            <Modal/>
            <div className={"flex min-h-full bg-default text-sm"}>
                <main className={mainClasses.join(' ')}>
                    <div className="container-fluid h-full">
                        {props.children}
                    </div>
                </main>
            </div>
        </>
    );
};

export default BaseLayout;
