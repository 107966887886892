import React from 'react';
import PropTypes from "prop-types";

import classes from './Spinner.module.css';

const spinner = (props) => {
    const classNames = [classes.Spinner];

    if (props.className) {
        classNames.push(props.className);
    }

    return (
        <span className={classNames.join(" ")}/>
    )
}

spinner.propTypes = {
    className: PropTypes.string
}

export default spinner;