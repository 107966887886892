import {get} from "../config";
import {v4 as uuidV4} from 'uuid';
import {getCookie, getLanguageCode, setCookie} from "../support/utility";

export const setupInstance = (instance) => {
    instance.interceptors.request.use(req => {
        if (req.method === 'post') {
            req.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
        }

        return req;
    });

    let deviceId = localStorage.getItem('DeviceId');
    if (!deviceId) {
        deviceId = getCookie('DeviceId');
    }

    if (!deviceId || !deviceId.startsWith("web-")) {
        deviceId = "web-" + uuidV4();
        localStorage.setItem('DeviceId', deviceId);
        setCookie('DeviceId', deviceId, 365);
    }

    // Trim last slash
    const pathname = window.location.pathname.replace(/\/+$/, '');

    instance.defaults.withCredentials = true;
    instance.defaults.crossDomain = true;
    instance.defaults.headers.common['Authorization'] = get('app::api.auth0');
    instance.defaults.headers.common['Platform'] = 'Web';
    instance.defaults.headers.common['App-Version-Number'] = '2';
    instance.defaults.headers.common['Language'] = getLanguageCode();
    instance.defaults.headers.common['Pathname'] = pathname;

    const deviceIdPathnames = [
        '/gifts/buy',
        '/en/gifts/buy',
    ];

    if (deviceIdPathnames.includes(pathname)) {
        instance.defaults.headers.common['Device-Id'] = deviceId;
    }
}